import { Modal } from '@mui/material';
import React, { useContext,useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'

import { WaitModalContext } from "../../../app/app.index";
import CarImage from '../../../assets/images/car.svg'
import TopSectionLogo from '../../../assets/images/Frame.svg'
import HowToOrder from '../../../assets/images/how-to-order.svg'
import ShopImage from '../../../assets/images/shop.svg'
import Button from '../../reusable/components/Button/button'
import SectionArrow from '../../reusable/components/icons/SectionArrow/section-arrow'
import AuthenticatedService from '../../services/AuthenticatedService';
import FarmLogo from './../../reusable/components/icons/FarmLogo/farm-logo';
import styles from './home.module.scss'


function Home() {
    const navigate = useNavigate()
    const [isClosed, setIsClosed] = useState(false)
    const steps = [
        { id: 1, heading: "Place your order", description: "Place and pay for your order via the app", image: ShopImage },
        { id: 2, heading: "Chill out", description: "Stay at your location and we will Deliver your order to you", image: CarImage },
    ]

    const openWaitTimeModal = () => {
        setWaitTimeModalOpen(true)
    };

    const gotoCategories = () => {
        if (AuthenticatedService.isOpen !== true) {
            setIsClosed(true)
            return
        }

        navigate('categories');
        openWaitTimeModal();
    }

    const [waitTimeModalOpen, setWaitTimeModalOpen] = useContext(WaitModalContext);

    return (
        <div className={styles.container}>
            <div className={styles['top-section-content']}>
                <div className={styles['top-section-logo']}>
                    <img src={TopSectionLogo} alt="First section logo" />
                    <div className={styles['start-order-box']}>
                        <Button
                            text="Start ordering"
                            classes={{
                                root: styles['start-order-box-button']
                            }}
                            onClick={gotoCategories}
                        />
                    </div>
                </div>
                <div className={styles['top-section-arrow']}>
                    <SectionArrow />
                </div>
            </div>
            <div className={styles['how-to-order-container']}>
                <img src={HowToOrder} alt="How to order image" />
                <div className={styles['steps']}>
                    {steps.map((step) =>
                        <div className={styles['step']} key={step.heading}>
                            <img src={step.image} alt={`${step.id} image`} />
                            <h4 className='title_text_3'>{step.heading}</h4>
                            <p className={`body-text`}>{step.description}</p>
                        </div>
                    )}
                </div>
                <div className={styles['start-order-box']}>
                    <Button
                        text="Start ordering"
                        classes={{
                            root: styles['start-order-box-button']
                        }}
                        onClick={gotoCategories}
                    />
                </div>
            </div>
            <div className={styles['allergen-container']}>
                <h4 className='title_text_3'>Allergen Information</h4>
                <div className={styles['allergen-btn-container']}>
                    <Button
                        text="Download"
                        classes={{
                            root: styles['allergen-btn']
                        }}
                        onClick={() => window.open('https://www.theicecreamfarm.co.uk/wp-content/uploads/2024/12/Parlour-Allergens-2024.pdf', '_blank')}
                    />
                </div>
            </div>
            <div className={styles['footer-section']}>
                <Link to="/" className={styles['main-logo']}>
                    <FarmLogo fillColor="#ffffff" />
                </Link>
            </div>

            <Modal open={isClosed} onClose={() => setIsClosed(false)}>
                <div className={styles["modal"]}>
                    <p className="heading-text">
                        The Drive In is currently closed, sorry for any inconvenience
                    </p>
                </div>
            </Modal>
        </div>
    )
}

export default Home